import * as client from './client'
import Routes from '../routes/routes'
import country from 'commons/js/country'

import getFinesNotifierPrices from './driverPlus/getFinesNotifierPrices'
import createFinesNotifierSubscriptionForDni from './driverPlus/createFinesNotifierSubscriptionForDni'
import createFinesNotifierSubscriptionForNumberPlate from './driverPlus/createFinesNotifierSubscriptionForNumberPlate'
import cancelFinesNotifierSubscription from './driverPlus/cancelFinesNotifierSubscription'
import updateFinesNotifierSubscriptionLicense from './driverPlus/updateFinesNotifierSubscriptionLicense'
import getFinesNotifierSubscriptions from './driverPlus/getFinesNotifierSubscriptions'
import getFinesPointReport from './driverPlus/getFinesPointReport'
import getRentalCarPois from './rentalCar/getPois'
import getRentalCarContract from './rentalCar/getRentalCarContract'
import userServiceRegisterIntent from './userService/registerIntent'
import userServiceRegister from './userService/register'
import userServiceGetTerms from './userService/getTerms'
import searchRates from './rentalCar/searchRates'
import getInvoices from './getInvoices'
import createRentalCarContract from './rentalCar/createRentalCarContract'
import buildRentalCarBooking from './rentalCar/buildBooking'
import updateRentalCarBooking from './rentalCar/updateBooking'
import getCookies from './getCookies'
import getSerTicket from './getSerTicket'
import unParkSerTicket from './unParkSerTicket'
import getSerTicketUnParkInfo from './getSerTicketUnParkInfo'
import updateVehicleKm from './updateVehicleKm'
import login from './login'
import logout from './logout'
import getDepositTiers from './getDepositTiers'
import getUser from './getUser'
import getUserExists from './getUserExists'
import getViatParkingsByCountry from './getViatParkingsByCountry'
import getViatHighwayDiscounts from './getViatHighwayDiscounts'
import getViatOrderData from './getViatOrderData'
import getGlobalConfig from './getGlobalConfig'
import getCreditCards from './getCreditCards'
import getCreditCardInfo from './getCreditCardInfo'
import getUserWallets from './getUserWallets'
import getViatProducts from './getViatProducts'
import createCreditCard from './createCreditCard'
import payNewUserViatOrder from './payNewUserViatOrder'
import payExistingUserViatOrder from './payExistingUserViatOrder'
import createViatOrder from './createViatOrder'
import updateViatOrder from './updateViatOrder'
import confirmPaymentIntent from './confirmPaymentIntent'
import getViatOrders from './getViatOrders'
import cancelViatOrder from './cancelViatOrder'
import getProvincesForShop from './getProvincesForShop'
import bindWalletToMobe from './bindWalletToMobe'
import bindVehicleToMobe from './bindVehicleToMobe'
import unbindVehicleToMobe from './unbindVehicleToMobe'
import getMobes from './getMobes'
import getTolls from './getTolls'
import getPagedWalletBeneficiaries from './getPagedWalletBeneficiaries'
import getEnabledMobes from './getEnabledMobes'
import unassignMobe from './unassignMobe'
import createDeposit from './createDeposit'
import cancelWorkshopBooking from './cancelWorkshopBooking'
import getWorkshopBooking from './getWorkshopBooking'
import setWorkshopBookingProduct from './setWorkshopBookingProduct'
import getSuitableBookingWorkshops from './getSuitableBookingWorkshops'
import assignWorkshopToBookingWorkshop from './assignWorkshopToBookingWorkshop'
import setWorkshopBookingVehicle from './setWorkshopBookingVehicle'
import requestBrands from './requestBrands'
import getTwoFaActiveMethod from './getTwoFaActiveMethod'
import activeTwoFaActiveEmailMethod from './activeTwoFaActiveEmailMethod'
import deactivateTwoFaActiveEmailMethod from './deactivateTwoFaActiveEmailMethod'
import getVehicles from './getVehicles'
import updateBookingVehicle from './updateBookingVehicle'
import getBookingAvailabilityUpdateDates from './getBookingAvailabilityUpdateDates'
import updateBookingDates from './updateBookingDates'
import getWorkshopBookingOccupation from './getWorkshopBookingOccupation'
import confirmWorkshopBooking from './confirmWorkshopBooking'
import setWorkshopBookingDate from './setWorkshopBookingDate'
import updateUserData from './updateUserData'
import searchAirports from './airports/searchAirports'
import searchParkings from './parkings/searchParkings'
import getPagedVehicles from './getPagedVehicles'
import getWalletPreferences from './getWalletPreferences'
import getAutoDepositTiers from './getAutoDepositTiers'
import updateWalletPreferences from './updateWalletPreferences'
import getWallet from './getWallet'
import associateCreditCard from './associateCreditCard'
import uploadVehicleCsvFile from './uploadVehicleCsvFile'
import uploadTrailerCsvFile from './uploadTrailerCsvFile'
import getAdvancedVehicleAdmin from './getAdvancedVehicleAdmin'
import createTrailer from './createTrailer'
import getPagedTrailers from './getPagedTrailers'
import deleteTrailer from './deleteTrailer'
import deleteCreditCard from './deleteCreditCard'
import getWalletMovements from './getWalletMovements'
import uploadWalletBeneficiaryCsvFile from './uploadWalletBeneficiaryCsvFile'
import createWalletBeneficiary from './createWalletBeneficiary'
import deleteWalletBeneficiary from './deleteWalletBeneficiary'
import getUserPricePerWallet from './getUserPricePerWallet'
import updateCorporatePlatesWhitelistEnabled from './updateCorporatePlatesWhitelistEnabled'
import getPagedCorporateWhitelistedNumberPlates from './getPagedCorporateWhitelistedNumberPlates'
import deleteCorporateWhitelistedNumberPlate from './deleteCorporateWhitelistedNumberPlate'
import createCorporateWhitelistedNumberPlate from './createCorporateWhitelistedNumberPlate'
import uploadNumberPlateWhitelistCsvFile from './uploadNumberPlateWhitelistCsvFile'
import getWalletBeneficiariesAll from './getWalletBeneficiariesAll'
import sendMovementsToEmail from './sendMovementsToEmail'
import deleteAllCorporateWhitelistedNumberPlates from './deleteAllCorporateWhitelistedNumberPlates'
import getSerTowns from './getSerTowns'
import getSerFine from './getSerFine'
import paySerFine from './paySerFine'
import requestResetPassword from './requestResetPassword'
import resetPasswordUpdate from './resetPasswordUpdate'
import getSeasonTicketContract from './seasonTicket/getSeasonTicketContract'
import getSeasonTicketPrice from './seasonTicket/getSeasonTicketPrice'
import updateSeasonTicketContractVehicle from './seasonTicket/updateSeasonTicketContractVehicle'
import cancelSeasonTicketContract from './seasonTicket/cancelSeasonTicketContract'
import createSeasonTicketContract from './seasonTicket/createSeasonTicketContract'
import checkSeasonTicketAvailability from './seasonTicket/checkSeasonTicketAvailability'
import getBookedParkingPass from './getBookedParkingPass'
import getOfferedParkingPass from './getOfferedParkingPass'
import createBookedParkingPass from './createBookedParkingPass'
import cancelBookedParkingPass from './cancelBookedParkingPass'
import requestChangeEmail from './requestChangeEmail'
import updatePassword from './updatePassword'
import getSerZones from './getSerZones'
import getSerSteps from './getSerSteps'
import paySerTicket from './paySerTicket'
import updateVehicle from './updateVehicle'
import cancelVehicleInspectionBooking from './vehicleInspection/cancelVehicleInspectionBooking'
import requestVehicleInspectionBooking from './vehicleInspection/requestVehicleInspectionBooking'
import requestVehicleInspectionProducts from './vehicleInspection/requestVehicleInspectionProducts'
import searchVehicleInspectionStations from './vehicleInspection/searchVehicleInspectionStations'
import getOccupation from './vehicleInspection/getVehicleInspectionStationOccupation'
import getOccupationHours from './vehicleInspection/getVehicleInspectionStationOccupationHours'
import getBookingAvailability from './getBookingAvailability'
import getBookingProductsAvailability from './getBookingProductsAvailability'
import createPublicBooking from './createPublicBooking'
import createBooking from './createBooking'
import getPrebooking from './getPrebooking'
import updatePrebooking from './updatePrebooking'
import createPrebooking from './createPrebooking'
import createPublicPrebooking from './createPublicPrebooking'
import searchPois from './pois/searchPois'
import getBooking from './getBooking'
import cancelBooking from './cancelBooking'
import getParking from './getParking'

import getProductsSummary from './merchant/getProductsSummary'
import getConfig from './merchant/getConfig'
import getAvailableProducts from './merchant/getAvailableProducts'
import getBusinessInformation from './merchant/getBusinessInformation'
import saveBusinessInformation from './merchant/saveBusinessInformation'
import saveOrder from './merchant/saveOrder'
import getOrder from './merchant/getOrder'
import getLastOrders from './merchant/getLastOrders'

import createDeepLink from './createDeepLink'

import {
    buildAirport,
    buildWallet,
    mapFetchWalletParams,
    buildError,
    buildPrivacy,
    buildDeleteVehicleError,
    buildSetupIntent,
    mapAccount,
    mapContactForm,
    mapPreferences,
    buildPreferences,
    mapSetupIntent,
    mapBooking,
    buildBooking,
    buildBookingError,
    buildVehicles,
    buildVehicle,
    mapCreateVehicle,
    buildClientError,
    buildTowns,
    buildProvinces,
    mapWalletInvoiceData,
    mapWalletInvoiceDataUrlParams,
    buildWalletInvoiceData,
    buildActiveContracts,
    buildInactiveContracts,
    mapSearchChargingPoint,
    buildChargingPoints,
    buildChargingPointBooking,
    buildRotation,
    buildEnvironmentalLabel,
    mapRotation,
    mapLanguages,
    mapPrivacy,
    buildVehicleError,
} from './converters'

export const TIMEOUT = 1E6

export async function requestEnvironmentalLabelNumber (numberPlate) {
    const url = Routes.apiGetEnvironmentalLabelNumber({ numberPlate })

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildEnvironmentalLabel(result) }
    }

    return { error: buildClientError(result) }
}

export async function searchChargingPoints (search) {
    const url = Routes.apiSearchChargingPoints(mapSearchChargingPoint(search))

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildChargingPoints(result) }
    }

    return { error: buildError(result) }
}

export async function getAirport (search) {
    const url = Routes.apiFetchAirport(search)

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildAirport(result) }
    }

    return { error: buildError(result) }
}

export async function getAcceptedCountries () {
    const url = Routes.getAcceptedCountries()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { ...result }
    }

    return { error: result }
}

export async function getTOS () {
    const url = Routes.getTOS()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { ...result }
    }

    return { error: result }
}

export async function acceptTOS (version) {
    const url = Routes.acceptTOS()

    const { result, status } = await client.post(url, { version })

    if (status === 'success') {
        return { ...result }
    }

    return { error: result }
}

export async function setPrivacy ({ privacy }) {
    const url = Routes.setPrivacy()

    const { result, status } = await client.put(url, mapPrivacy(privacy))

    if (status === 'success') {
        return { result: buildPrivacy(result) }
    }

    return { error: result }
}

export async function createAccount (account) {
    const url = Routes.apiCreateAccount()

    const { result, status } = await client.post(url, mapAccount(account))

    if (status === 'success') {
        return { result }
    }

    return { error: result }
}

export async function sendContactForm (values) {
    const url = Routes.apiSendContactForm()

    const { result, status } = await client.post(url, mapContactForm(values))

    if (status === 'success') {
        return { result }
    }

    return { error: result }
}

export async function createSetupIntent (params) {
    const url = Routes.apiCreateSetupIntent(mapSetupIntent(params))

    const { result, status } = await client.post(url)

    if (status === 'success') {
        return { result: buildSetupIntent(result) }
    }

    return { error: result }
}

export async function requestBookingInfo (search) {
    const url = Routes.apiBookingPath(mapBooking(search))

    try {
        const { result } = await client.get(url)

        return { result: buildBooking(result) }
    } catch (error) {
        return { error: buildBookingError(error) }
    }
}

export async function requestRotationInfo (search) {
    const url = Routes.apiRotationPath(mapRotation(search))

    try {
        const { result } = await client.get(url)

        return { result: buildRotation(result) }
    } catch (error) {
        return { error: buildClientError(error) }
    }
}

export async function cancelChargingPointBooking ({ bookingId }) {
    const url = Routes.apiCancelChargingPointBookingPath({ chargingPointBookingId: bookingId })
    try {
        const { result } = await client.del(url)

        return { result: buildChargingPointBooking(result) }
    } catch (error) {
        return { error: buildBookingError(error) }
    }
}

export async function requestLanguages ({ page }) {
    const url = Routes.getAcceptedCountries()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: mapLanguages(result, country) }
    }

    return { error: buildClientError(result) }
}

export async function requestVehicles () {
    const url = Routes.apiGetVehicles()

    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildVehicles({ items: result }) }
    }

    return { error: buildClientError(result) }
}

export async function requestDeleteVehicle ({ vehicle }) {
    const url = Routes.apiDeleteVehicle({ idVehicle: vehicle.id })

    const { result, status } = await client.del(url)

    if (status === 'success') {
        return { result: { id: vehicle.id } }
    }

    return { error: buildDeleteVehicleError(result), result: { id: vehicle.id } }
}

export async function savePreferences ({ preferences }) {
    const url = Routes.apiSavePreferences()
    const { result, status } = await client.put(url, mapPreferences(preferences))

    if (status === 'success') {
        return { result: buildPreferences(result) }
    }

    return { error: buildError(result) }
}

export async function createVehicle ({ vehicle }) {
    const url = Routes.apiCreateVehicle()

    const { result, status } = await client.post(url, mapCreateVehicle(vehicle))
    if (status === 'success') {
        return { result: buildVehicle(result) }
    }

    return { error: buildVehicleError(result), result: vehicle }
}

export async function requestInvoiceXlsx ({ invoiceUid }) {
    const url = Routes.apiRequestInvoiceXlsx({ invoiceUid })
    const { result, status } = await client.get(url)

    if (status === 'success') {
        return {}
    }
    return { error: buildClientError(result) }
}

export async function getProvinces () {
    const url = Routes.apiRequestProvinces()
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildProvinces(result) }
    }

    return { error: buildClientError(result) }
}

export async function getTownsByProvince (id) {
    const url = Routes.apiRequestTownsByProvince({ id })
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildTowns(result) }
    }

    return { error: buildClientError(result) }
}

export async function getWalletInvoiceData ({ walletId }) {
    const url = Routes.apiRequestWalletInvoiceData(mapWalletInvoiceDataUrlParams({ walletId }))
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildWalletInvoiceData(result) }
    }

    return { error: buildClientError(result) }
}

export async function getPrivacy () {
    const url = Routes.apiGetPrivacy()
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildPrivacy(result) }
    }

    return { error: buildClientError(result) }
}

export async function updateWalletInvoiceData ({ walletInvoiceData, walletId }) {
    const url = Routes.apiUpdateWalletInvoiceData(mapWalletInvoiceDataUrlParams({ walletId }))
    const { result, status } = await client.put(url, mapWalletInvoiceData(walletInvoiceData))
    if (status === 'success') {
        return { result: buildWalletInvoiceData(result) }
    }

    return { error: buildClientError(result) }
}

export async function requestWalletData ({ walletId }) {
    const url = Routes.apiRequestWalletData(mapFetchWalletParams(walletId))
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildWallet(result) }
    }

    return { error: buildClientError(result) }
}

export async function getActiveContracts () {
    const url = Routes.apiGetActiveContracts()
    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildActiveContracts(result) }
    }

    return { error: buildClientError(result) }
}

export async function getInactiveContracts ({ page, contractTypes }) {
    const url = Routes.apiGetInactiveContracts({ page, 'contract_types': contractTypes.join(',') })
    const { result, status } = await client.get(url)

    if (status === 'success') {
        return { result: buildInactiveContracts(result) }
    }

    return { error: buildClientError(result) }
}

export async function sendSerTicket (id) {
    const url = Routes.serTicketSend({ idSERTicket: id })
    const { status } = await client.get(url)
    if (status === 'success') {
        return { error: false }
    }

    return { error: true }
}

export async function requestChargingPointBooking ({ bookingId }) {
    const url = Routes.apiRequestChargingPointBooking({ chargingPointBookingId: bookingId })
    const { result, status } = await client.get(url)
    if (status === 'success') {
        return { result: buildChargingPointBooking(result) }
    }

    return { error: buildClientError(result) }
}

export {
    userServiceRegisterIntent,
    userServiceRegister,
    userServiceGetTerms,
    login,
    logout,
    getUser,
    searchRates,
    getUserExists,
    getViatParkingsByCountry,
    getViatHighwayDiscounts,
    uploadVehicleCsvFile,
    uploadTrailerCsvFile,
    uploadWalletBeneficiaryCsvFile,
    createWalletBeneficiary,
    deleteWalletBeneficiary,
    getOccupation,
    getOccupationHours,
    getViatOrderData,
    getCreditCards,
    getUserWallets,
    getUserPricePerWallet,
    getCreditCardInfo,
    getViatProducts,
    payNewUserViatOrder,
    payExistingUserViatOrder,
    getViatOrders,
    createViatOrder,
    updateViatOrder,
    confirmPaymentIntent,
    cancelViatOrder,
    getProvincesForShop,
    getMobes,
    getTolls,
    getPagedWalletBeneficiaries,
    bindVehicleToMobe,
    unbindVehicleToMobe,
    bindWalletToMobe,
    unassignMobe,
    getEnabledMobes,
    getGlobalConfig,
    setWorkshopBookingProduct,
    assignWorkshopToBookingWorkshop,
    getSuitableBookingWorkshops,
    setWorkshopBookingVehicle,
    getWorkshopBooking,
    cancelWorkshopBooking,
    requestBrands,
    getTwoFaActiveMethod,
    activeTwoFaActiveEmailMethod,
    deactivateTwoFaActiveEmailMethod,
    getVehicles,
    updateBookingVehicle,
    getBookingAvailabilityUpdateDates,
    updateBookingDates,
    getWorkshopBookingOccupation,
    createCreditCard,
    confirmWorkshopBooking,
    setWorkshopBookingDate,
    updateUserData,
    updateVehicleKm,
    getPagedVehicles,
    getWalletPreferences,
    getWallet,
    associateCreditCard,
    getAutoDepositTiers,
    updateWalletPreferences,
    getAdvancedVehicleAdmin,
    createDeposit,
    createTrailer,
    getPagedTrailers,
    deleteTrailer,
    getDepositTiers,
    deleteCreditCard,
    getWalletMovements,
    updateCorporatePlatesWhitelistEnabled,
    getPagedCorporateWhitelistedNumberPlates,
    sendMovementsToEmail,
    deleteCorporateWhitelistedNumberPlate,
    createCorporateWhitelistedNumberPlate,
    uploadNumberPlateWhitelistCsvFile,
    getWalletBeneficiariesAll,
    getSerTowns,
    getSerFine,
    getFinesNotifierPrices,
    createFinesNotifierSubscriptionForDni,
    createFinesNotifierSubscriptionForNumberPlate,
    cancelFinesNotifierSubscription,
    updateFinesNotifierSubscriptionLicense,
    getFinesNotifierSubscriptions,
    getFinesPointReport,
    paySerFine,
    deleteAllCorporateWhitelistedNumberPlates,
    requestResetPassword,
    resetPasswordUpdate,
    updateSeasonTicketContractVehicle,
    getSeasonTicketContract,
    cancelSeasonTicketContract,
    getSeasonTicketPrice,
    createSeasonTicketContract,
    getBookedParkingPass,
    checkSeasonTicketAvailability,
    getOfferedParkingPass,
    createBookedParkingPass,
    cancelBookedParkingPass,
    requestChangeEmail,
    updatePassword,
    getSerTicket,
    unParkSerTicket,
    getSerZones,
    updateVehicle,
    getSerSteps,
    getSerTicketUnParkInfo,
    paySerTicket,
    getCookies,
    getRentalCarPois,
    createRentalCarContract,
    getRentalCarContract,
    buildRentalCarBooking,
    updateRentalCarBooking,
    getInvoices,
    cancelVehicleInspectionBooking,
    requestVehicleInspectionBooking,
    requestVehicleInspectionProducts,
    searchVehicleInspectionStations,
    getBookingAvailability,
    getBookingProductsAvailability,
    createPublicBooking,
    createBooking,
    getPrebooking,
    updatePrebooking,
    createPrebooking,
    createPublicPrebooking,
    searchAirports,
    searchParkings,
    searchPois,
    getBooking,
    cancelBooking,
    getParking,
    getProductsSummary,
    getConfig,
    getAvailableProducts,
    getBusinessInformation,
    saveBusinessInformation,
    saveOrder,
    getOrder,
    getLastOrders,
    createDeepLink,
}
