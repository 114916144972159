import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import buildOptions from './buildApiOptions'

function mapDeepLinkPayload ({name, parameters}) {
    return {
        name,
        parameters,
    }
}

export default buildPostApi({
    route: Routes.apiCreateDeepLink,
    mapPayload: mapDeepLinkPayload,
    buildOptions,
})
