import { getIntFromString } from './rentalCar'
import { mapRentalCarPOI } from './rentalCarPOI'

export const buildRentalCarBooking = ({
    id,
    idSearch,
    uuid,
    startDate,
    endDate,
    originPOI,
    destinationPOI,
    rate,
    extras,
    fixedCoverages,
    coverages,
    priceDetails,
    additionalInfoUrl,
}) => ({
    id: id,
    idSearch: idSearch,
    uuid: uuid,
    startDate: startDate / 1000,
    endDate: endDate / 1000,
    originPOI: {
        ...mapRentalCarPOI(originPOI),
        address: rate.originStationAddress,
        code: rate.originStationCode,
        lat: rate.originStationLat,
        lon: rate.originStationLon,
        stationName: rate.originStationName,
        phoneNumber: rate.originStationPhoneNumber,
    },
    destinationPOI: {
        ...mapRentalCarPOI(destinationPOI),
        address: rate.destinationStationAddress,
        code: rate.destinationStationCode,
        lat: rate.destinationStationLat,
        lon: rate.destinationStationLon,
        stationName: rate.destinationStationName,
        phoneNumber: rate.destinationStationPhoneNumber,
    },
    rate: buildRate(rate),
    extras: extras.map((extra) => buildRentalCarExtra({...extra})).filter(
        ({ id }, index, self) => index === self.findIndex((t) => t.id === id)
    ),
    fixedCoverages: fixedCoverages.map((coverage) => buildRentalCarCoverage(coverage)).filter(
        ({ id }, index, self) => index === self.findIndex((t) => t.id === id)
    ),
    coverages: coverages.map((coverage) => buildRentalCarExtra(coverage)).filter(
        ({ id }, index, self) => index === self.findIndex((t) => t.id === id)
    ),
    priceDetails: priceDetails.map((detail) => ({
        title: detail.title,
        displayPrice: detail.displayPrice,
        qty: {
            amount: detail.price || 0,
            currency: detail.currency || 'EUR',
        },
        qtySelected: detail.qtySelected,
        type: detail.type,
        description: detail.description,
        logoUrl: detail.logoUrl,
    })),
    additionalInfoUrl,
})

export const buildRate = ({
    additionalInfoUrl,
    id,
    idSupplier,
    categoryName,
    categoryLogoUrl,
    companyLogoUrl,
    categoryDescription,
    fuel,
    gearBox,
    doors,
    seats,
    baggage,
    vehicleCode,
    cancelFree,
    category,
    destinationStationAddress,
    destinationStationCode,
    destinationStationLat,
    destinationStationLon,
    destinationStationName,
    destinationStationPhoneNumber,
    displayPrice,
    distanceMeters,
    insuranceConfigs,
    insuranceTags,
    originStationAddress,
    originStationCode,
    originStationLat,
    originStationLon,
    originStationName,
    originStationPhoneNumber,
    price,
    currencySymbol,
    subCategory,
    subCategoryName,
    timeZone,
    vehicleType,
    supplierName,
    supplierWeb,
    displayDepositAmount,
}) => ({
    id,
    seller: {
        id: idSupplier,
        name: supplierName,
        image: companyLogoUrl,
    },
    car: {
        type: categoryName,
        description: categoryDescription,
        image: categoryLogoUrl || '',
        features: {
            oilType: fuel,
            gear: gearBox,
            doors: getIntFromString(doors),
            seats: getIntFromString(seats),
            suitcase: getIntFromString(baggage),
        },
        id: vehicleCode,
    },
    category,
    cancelFree,
    destinationStationAddress,
    destinationStationCode,
    destinationStationLat,
    destinationStationLon,
    destinationStationName,
    destinationStationPhoneNumber,
    displayPrice,
    distanceMeters,
    insuranceConfigs,
    insuranceTags,
    originStationAddress,
    originStationCode,
    originStationLat,
    originStationLon,
    originStationName,
    originStationPhoneNumber,
    price: {
        amount: price,
        currency: currencySymbol,
    },
    subCategory,
    subCategoryName,
    timeZone,
    vehicleType,
    additionalInfoUrl,
    supplierName,
    supplierWeb,
    displayDepositAmount,
})
export const buildRentalCarExtra = ({
    id,
    name,
    subName,
    code,
    description,
    logoUrl,
    type,
    qtyMax,
    frontType,
    currency,
    customerPrice,
    displayCustomerPrice,
    qtySelected,
    includedInEstTotalInd,
    customerMaxPrice,
}) => ({
    id,
    name,
    subName,
    code,
    description,
    logoUrl,
    type,
    qtyMax,
    frontType,
    currency,
    customerPrice,
    displayCustomerPrice,
    qtySelected,
    includedInEstTotalInd,
    customerMaxPrice,
    qty: {
        amount: customerPrice,
        currency,
    },
})

export const buildRentalCarCoverage = ({
    id,
    name,
    code,
    description,
    idSupplier,
    logoUrl,
    type,
    qtyMax,
    qtySelected = 0,
    frontType,
    includedInEstTotalInd,
    internalDescription,
}) => ({
    id,
    name,
    code,
    description,
    idSupplier,
    logoUrl,
    type,
    qtyMax,
    frontType,
    qtySelected,
    includedInEstTotalInd,
    internalDescription,
})
